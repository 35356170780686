.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.picker-modal-mask {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.picker-modal {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  background-color: #efeff4;
  backface-visibility: hidden;
  transition: transform .3s;
  transform: translate(0, 100%);
}

.picker-modal-toggle {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  background-color: #efeff4;
  backface-visibility: hidden;
  transition: transform .3s;
  transform: translate(0, 0);
}

.picker-modal .header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background-color: #fbf9fe;
}

.picker-modal .header .title {
  flex: 1 1;
  color: #888;
}

.picker-modal .header a {
  text-decoration: none;
  color: #04BE02;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media(max-width:1000px) {
  body, input, textarea, table, div, td, th, input, label, button {
    font-size: large !important;
  }
}

.examTable-row-riskGroup {
  background: #FAEBD7;
}

.cerificate-item.ant-list-item:hover {
  background: #f6f6f6;
  cursor: pointer;
}